import React from "react";
import { Link } from "react-router-dom";

function BlogCards({ id, title, summary, imageUrl, altText }) {
  return (
    <div className="col-12 col-md-4 col-lg-3 mb-4">
      
      <Link
        to={`/blog/${id}`}
        className="card h-100 text-decoration-none text-dark"
      >
        <img
          src={imageUrl}
          className="card-img-top"
          alt={altText}
          style={{ height: "200px", objectFit: "cover" }}
        />
        <div className="card-body">
        <h5 className="card-title" style={{ fontSize: '1rem' }}>{title}</h5> {/* Reduced font size for title */}
      <p className="card-text" style={{ fontSize: '0.8rem' }}>{summary}</p> {/* Reduced font size for text */}

        </div>
      </Link>
    </div>
  );
}

export default BlogCards;
