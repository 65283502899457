import React, { useState, useEffect } from 'react';
import BlogCards from './BlogCards'; // Adjust the import path as needed

function BlogsPage() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    fetch('/blogPosts.json') // Fetching the data from the JSON file
      .then(response => response.json())
      .then(data => setBlogPosts(data))
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);

  return (
    <section id="blogs" className="bg-light py-5">
      <div className="container">
        <h1 className="text-center mt-4 mb-4">Blog</h1>
        <div className="row">
          {blogPosts.map((post, index) => (
            <BlogCards key={index} {...post} />
          ))}
        </div>
      </div>
      <div id="blog-end"></div>
    </section>

  );
}

export default BlogsPage;
