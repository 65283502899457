import React from "react";

function Navbar() {
  return (
    // <nav className="navbar navbar-expand-lg navbar-light bg-light  justify-content-end">
    // <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top justify-content-end">
    <nav className="navbar navbar-expand-lg navbar-light custom-navbar-bg fixed-top">
      <div className="navbar-logo">
        <a className="navbar-brand" href="#home">
        <img src="/logo_icon.png" alt="meerkat-logo" />
        </a>
      </div>

      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <div className="navbar-item-custom-frame">
            <li className="nav-item">
              <a className="nav-link" href="#home">
                Ana Sayfa
              </a>
            </li>
          </div>

          <div className="navbar-item-custom-frame">
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Uygulamalar
              </a>
            </li>
          </div>

          <div className="navbar-item-custom-frame">
            <li className="nav-item ">
              <a className="nav-link" href="#news">
                Haberler
              </a>
            </li>
          </div>
          <div className="navbar-item-custom-frame">
            <li className="nav-item">
              <a className="nav-link" href="#blogs">
                Blog
              </a>
            </li>
          </div>
          <div className="navbar-item-custom-frame">
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                İletişim
              </a>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;


