import React from 'react';

function SecondPage() {
  return (
    <section id="second-page" className="bg-light py-5">
      <div  className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className="mb-3">Uygulanabilir Yaratıcı Çözümler</h1>
            <p className="lead">Eğitimli ve tecrübeli personelimizle iş dünyasına değer katmaya devam ediyoruz.</p>
          </div>
          <div className="col-md-6 text-center">
            <img className="img-fluid w-75" src="/assets/logo_maskli.png" alt="Descriptive Alt Text" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecondPage;
