
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import logo from "./logo.svg";
import "./App.css";
import Navbar from './components/Navbar';
import HomePageHero from "./components/HomePageHero";
import SecondPage from "./components/SecondPage";
import ServicesPage from "./components/ServicesPage";
import BlogsPage from "./components/BlogsPage";
import BlogDetail from "./components/BlogDetail";
import NewsPage from "./components/NewsPage";
import ContactPage from "./components/ContactPage";

function App() {
  return (
    <Router>
      <div>
       <Navbar />
        <HomePageHero />
        <SecondPage />
        <ServicesPage />
        <NewsPage/>
        <BlogsPage />

      </div>
      <div>
        <Routes>
          <Route path="/blog/:id" element={<BlogDetail />} />
        </Routes>
      </div>
      <div>
      <ContactPage />
      </div>
    </Router>
  );
}

export default App;



// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Navbar from './components/Navbar';
// import HomePageHero from './components/HomePageHero';
// import SecondPage from './components/SecondPage';
// import ServicesPage from './components/ServicesPage';
// import NewsList from './components/NewsList';
// import BlogsPage from './components/BlogsPage';
// import BlogDetail from './components/BlogDetail';

// function App() {
//   return (
//     <Router>
//       <Navbar /> {/* Include the Navbar component */}
//       <div>
//         <Routes>
//           <Route path="/" element={<HomePageHero />} />
//           <Route path="/second-page" element={<SecondPage />} />
//           <Route path="/services" element={<ServicesPage />} />
//           <Route path="/news" element={<NewsList />} />
//           <Route path="/blogs" element={<BlogsPage />} />
//           <Route path="/blog/:id" element={<BlogDetail />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;