import React from "react";

function BlogDetailContent({ content }) {
  // Function to create markup
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    // <section id="blog-detail">
    <section class="mt-4 p-4">
      <div className="container">
        {/* Image and video elements */}
        {content.content_image_url && (
          <div className="blog-content-image-custom-frame">
            <img
              src={content.content_image_url}
              alt="Blog Content"
              className="img-fluid w-50 rounded mx-auto d-block"
            />
          </div>
        )}
        {content.content_video_url && (
          <div className="blog-content-image-custom-frame">
            <video
              controls
              src={content.content_video_url}
              className="img-fluid w-50 rounded mx-auto d-block"
            />
          </div>
        )}

        <div dangerouslySetInnerHTML={createMarkup(content.content_body)} />
      </div>
    </section>
  );
}

export default BlogDetailContent;
