import React from "react";

function ContactPage() {
  return (
    <section className="mt-5 p-5" id="contact">
      <div className="container mt-5 p-5">
        <div className="row mt-5 p-5">
          <div>
            <p>
              Bize ulaşın ve Meerkat'ın yenilikçi yapay zeka çözümleri
              aracılığıyla size nasıl yardımcı olabileceğimiz konusunda birlikte
              kafa yoralım.
            </p>
          </div>
          <div className="mt-5 pt-5">
            <h2>İletişim</h2>
          </div>

          {/* <h4 class="mb-3">E-Mail</h4> */}
          <p>meerkat-cv@meerkat-cv.com</p>
        </div>
      </div>
    </section>
  );
}

export default ContactPage;
