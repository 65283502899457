import React from 'react';
import ServiceItem from './ServiceItem'; // Adjust the import path as needed

function ServicesPage() {
  // You can also fetch this data from an API or a file
  const services = [
    {
      title: "Güvenlik ve Gözetim",
      description: "Güvenlik kameraları ve gözetim sistemleri, bilgisayarlı görü ve derin öğrenme ile hareket algılama, yüz tanıma ve nesne takibi gibi özellikleri kullanarak kontrol alanlarını izlenebilir. Ayrıca hastane ve benzeri ortamlarda yanlış uygulamaların tespiti ve iş güvenliği için kullanılırlar.",
      imageUrl: "/assets/services/Security_Surveillance.png",
      altText: "Güvenlik ve Gözetim",
    },
    {
      title: "Kişisel Bilgilerin Anonimleştirilmesi",
      description: "PDF, resim vb. dosyalardaki özel bilgilerin tespit edilmesi ve gizlenmesi, sadece istenen alanların paylaşılmasını sağlar, böylece kişisel dokümanlar anonimleştirilir.",
      imageUrl: "/assets/services/anonimlestirme.png",
      altText: "Kişisel Bilgilerin Anonimleştirilmesi"
  },
  {
      title: "Depo Yönetimi",
      description: "Farklı türdeki kutu, paket ve benzeri ürünlerin sayılması, teslim alma ve çıkışlarda kayıt altına alınması, hasarlı kutuların tespiti ve kamera barkod sistemi ile kolay entegrasyon.",
      imageUrl: "/assets/services/warehouse.png",
      altText: "Depo Yönetimi"
  },
  {
      title: "Sağlık ve Tıp",
      description: "Röntgenlerden MR görüntülerine kadar tıbbi görüntüleme tekniklerinde kullanılır, doktorlara hastalık teşhisi ve tedavi planlaması için ek bilgi sağlar.",
      imageUrl: "/assets/services/xray_2.png",
      altText: "Sağlık ve Tıp"
  },

  {
    title: "Perakende ve E-ticaret",
    description: "Raflardaki ürünlerin izlenmesi, envanter yönetimi ve müşteri davranışını anlama; müşterilere önerilen ürünlerin yaş, cinsiyet vb. gibi faktörlere dayalı olarak sunulması.",
    imageUrl: "/assets/services/retail2.png",
    altText: ""
},
{
    title: "Tarım",
    description: "Bitkilerin hastalıklarını tespit etme, verimliliği artırma, hasat verilerini toplama ve tarım alanlarının izlenmesi için kullanılır.",
    imageUrl: "/assets/services/farm.png",
    altText: ""
},
{
    title: "Sanayi Otomasyonu",
    description: "Ürün kalitesinin kontrolü, robot kontrolü ve üretim süreçlerinin optimize edilmesi için kullanılır, ayrıca üretim esnasında insan tarafından tespit edilmesi güç hataların tespiti ve takibi sağlanır.",
    imageUrl: "/assets/services/car_production.png",
    altText: ""
},
// {
//     title: "Tıbbi Görüntü Analizi",
//     description: "Tıbbi görüntülerde lezyonların, tümörlerin ve anormalliklerin tespiti ve teşhisi yapılır.",
//     imageUrl: "/assets/services/xray.jpg",
//     altText: ""
// },
{
    title: "Dijital İşaretler ve Reklamcılık",
    description: "Dijital reklam panoları, yaklaşan kişileri algılayarak içeriklerini değiştirebilir ve daha etkili reklamlar sunabilirler.",
    imageUrl: "/assets/services/digital_ad_2.png",
    altText: ""
},
{
    title: "Eğitim",
    description: "Öğrenci ilerlemesini izleme, özelleştirilmiş öğrenme deneyimleri sunma ve eğitimde derin öğrenme kullanılabilir.",
    imageUrl: "/assets/services/education_ai.png",
    altText: ""
},
{
    title: "Görüntü Oluşturma",
    description: "Generative AI, gerçekçi görüntüleri ve videoları oluşturmak için kullanılabilir",
    imageUrl: "/assets/services/image_generation.png",
    altText: ""
}


  ];

  return (
    <section id="services" className="bg-light py-5 ">
      <div className="container overflow-hidden mt-4 ">
        <div className="row gy-5 gx-md-4 gy-lg-0 gx-xxl-5 justify-content-center">
          {services.map((service, index) => (
            <ServiceItem key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesPage;

