import React from 'react';

function ServiceItem({ title, description, imageUrl, altText }) {
  return (
    <div className="col-11 col-sm-6 col-lg-3 mb-4">
      <div className="card h-100">
        <img src={imageUrl} className="card-img-top" alt={altText} />
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
}
export default ServiceItem;


// function ServiceItem({ title, description, imageUrl, altText }) {
//   return (
//     <div className="col-11 col-sm-6 col-lg-3">
//       <div className="badge text-primary p-3 mb-4">
//         <img className="img-fluid w-75" src={imageUrl} alt={altText} />
//       </div>
//       <h4 className="mb-3">{title}</h4>
//       <p className="mb-3 text-secondary">{description}</p>
//     </div>
//   );
// }
