import React from "react";

// function HomePageHero() {
//   return (
//     <div id="home">
//       {/* content of HomePageHero */}
//     </div>
//   );
// }

function HomePageHero() {
  return (
    <section id="home" className="bg-light py-5">
      <div  className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className="mb-3">Vizyonunuzu Gerçeğe Dönüştürüyoruz</h1>
            <p className="lead">
              Görüntüyü anlamak için buradayız. Son teknolojik gelişmeleri
              içeren ve sizi daha ileriye taşımak için pratik uygulamalara
              odaklanan yöntemlerimizle öne çıkın.
            </p>

            <h5>MEERKAT</h5>
            <h5>Machine Eyes for Enhanced Real-time Kinetic Analysis & Technology</h5>
          </div>

          {/* <div className="col-md-6">

          </div> */}
          <div className="col-md-6 text-center">
            <img
              className="img-fluid w-75"
              src="/assets/home_maskli.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    
  );
}

export default HomePageHero;
