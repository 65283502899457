import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BlogDetailContent from "./BlogDetailContent"; // Import the new component

function BlogDetail() {
  let { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    fetch("/blogPosts.json")
      .then((response) => response.json())
      .then((data) => {
        const post = data.find((p) => p.id === id);
        setBlogPost(post);
        document.getElementById('blog-end').scrollIntoView();

      })
      .catch((error) => console.error("Error fetching blog data:", error));
  }, [id]);



  if (!blogPost) return <div>Loading...</div>;


  return (
    <section id="blog-detail" class="mt-4 p-4">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h1 class="mt-4 pt-4" style={{ marginBottom: "20px" }}>{blogPost.title}</h1>
          <BlogDetailContent content={blogPost.content} />
          
        </div>
      </div>
    </div>
    </section>
  );
}

export default BlogDetail;
