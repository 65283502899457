import React, { useState } from "react";

const NewsSection = ({ news }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    // section newslist te
      <div className="news-section">
        <h4
          onClick={toggleVisibility}
          className="news-title"
          style={{ cursor: "pointer" }}
        >
          {news.title}
        </h4>
        {isVisible && <p className="news-content">{news.content}</p>}
      </div>
  );
};

export default NewsSection;

// const NewsSection = ({ news }) => {
//   return (
//     <div className="news-section">
//       <h2 className="news-title">{news.title}</h2>
//       <p className="news-content">{news.content}</p>
//     </div>
//   );
// };

// export default NewsSection;
