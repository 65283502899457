import React, { useState, useEffect } from 'react';
import NewsSection from './NewsSection';

const NewsPage = () => {
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    fetch('/newsItems.json')
      .then(response => response.json())
      .then(data => setNewsItems(data))
      .catch(error => console.error('Error fetching news data:', error));
  }, []);

  return (
    <section id="news" className="bg-light py-5">
      <div className="container">
        <div className="col-md-8 offset-md-2">
          <h1 className="text-center mb-4 mt-4 ">Güncel</h1>
          {newsItems.map(news => (
            <NewsSection key={news.id} news={news} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsPage;



